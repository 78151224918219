import PodcastBannerComponent from '@pretto/bricks/website/shared/components/PodcastBanner'

import * as S from '../styles'

const PodcastBanner = () => (
  <S.BlockLarge>
    <PodcastBannerComponent />
  </S.BlockLarge>
)

export default PodcastBanner
