import styled from 'styled-components'

import { breakpoints, g } from '@pretto/bricks/components/layout'

export const Content = styled.div`
  margin-bottom: ${g(2)};

  @media screen and (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${g(9)};
  }
`
export const ButtonContainer = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    position: absolute;
    left: ${g(3)};
    bottom: ${g(3)};
  }
`
