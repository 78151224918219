import ButtonLegacy from '@pretto/bricks/components/buttons/ButtonLegacy'
import MarketingBannerTemplate from '@pretto/bricks/website/shared/components/MarketingBannerTemplate'

import nl2br from 'react-nl2br'

import * as S from './styles'

const PodcastBanner = props => {
  const bannerProps = {
    ...props,
    coverPath: 'v1583514128/website/guide/podcast-cover-v2.png',
    title: nl2br('L’amour est dans le prêt\nLe podcast de Pretto'),
  }
  return (
    <MarketingBannerTemplate {...bannerProps}>
      <S.Content>
        En 2h30, en voiture, au bureau ou en faisant le ménage, peu importe vos préférences : vous serez bientôt un as
        du prêt immobilier
      </S.Content>
      <S.ButtonContainer>
        <ButtonLegacy href="/podcast-pret-immobilier/" variant="accent-1">
          Écouter
        </ButtonLegacy>
      </S.ButtonContainer>
    </MarketingBannerTemplate>
  )
}

export default PodcastBanner
